import React, { useState, useEffect } from "react";
import "./Upload.scss";
import logo from "./../../Assets/uploadLogo.svg";
import * as XLSX from "xlsx/xlsx.mjs";
import { set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import Modal from "react-modal";
import crossIcon from "../../Assets/cross-icon.svg";
import FileShow3 from "../FileShow/FileShow3";
import { CircularProgress, Stack, Typography } from "@mui/material";
import TablePage3 from "../../Pages/TablePage/TablePage3";
import firebase from "firebase";
import Loader from "../Loader/Loader";

set_cptable(cptable);
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    minWidth: "550px",
    right: "auto",
    bottom: "auto",
    borderRadius: "24px",
    boxShadow:
      "-16px -16px 40px rgba(253, 255, 255, 0.8), 16px 16px 40px rgba(187, 195, 206, 0.6)",
    padding: "0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

function Upload3({ masterData, loading }) {
  const [fileName, setFileName] = useState([]);
  const [fileData, setFileData] = useState();
  const [errorList, setErrorList] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [invoiceAlphabets, setInvoiceAlphabets] = useState();
  const [loader, setLoader] = useState(false);

  const [invoiceCode, setInvoiceCode] = useState();
  const [options, setOptions] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYears, setSelectedYears] = useState("");

  const [data, setData] = useState([]);
  const [progress, setProgress] = useState("20%");
  const [progressState, setProgressState] = useState("File Uploaded");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [nameMasterCode, setNameMasterCode] = useState();
  const [customDate, setCustomDate] = useState();
  const [classValue, setClassValue] = useState();
  const [uploadedFileData, setUploadedFileData] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const ref = firebase.firestore().collection("Master List2");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const mapErrorLists = (errorData) => {
    if (!errorData || !Array.isArray(errorData)) {
      console.log("Invalid or empty errorData");
      return [];
    }

    console.log("functioncall");
    const result = errorData.map((value) => {
      const formData = fileData.get(value); // Assuming `fileData` is a Map or similar structure
      console.log(formData, "FORMDATA");
      return formData;
    });

    return result; // Return the mapped array
  };

  const validateFields = () => {
    const errors = [];

    if (!invoiceCode) errors.push("Please select an option for Invoice Code!");

    if (!invoiceAlphabets) {
      errors.push("Alphabets are required!");
    } else if (invoiceAlphabets.length > 3) {
      errors.push("Alphabets must not exceed 3 characters!");
    } else if (/[^a-zA-Z]/.test(invoiceAlphabets)) {
      errors.push("Alphabets must contain only letters!");
    }

    if (!invoiceNumber || isNaN(invoiceNumber))
      errors.push("Invoice Number is required and must be numeric!");

    if (!selectedYears) errors.push("Year is required!");

    if (!customDate) errors.push("Date is required!");
    if (!classValue) errors.push("Class Name is required!");

    return errors;
  };
  const InvoiceSubmit = async () => {
    const errors = validateFields();

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    localStorage.setItem("version3_number", invoiceNumber);
    localStorage.setItem("version3_code", invoiceCode);
    localStorage.setItem("version3_date", customDate);
    localStorage.setItem("version3_alphabets", invoiceAlphabets);
    localStorage.setItem("version3_year", selectedYears);
    localStorage.setItem("version3_class", classValue);

    if (uploadedFileData) {
      setUploaded(true);
      await readExcelToJson();
    }
    closeModal();
  };

  const handleUpload = async (e) => {
    openModal();
    setUploadedFileData(e.target.files[0]);
    e.target.value = "";
  };

  function excelSerialToDate(serial) {
    // Base date for Excel: 1900-01-01 (Excel's leap year bug is accounted for by subtracting 2 days)
    const excelBaseDate = new Date(1900, 0, 1);
    excelBaseDate.setDate(excelBaseDate.getDate() + serial - 2); // Adjust for Excel leap year bug

    // Convert the date to ISO 8601 format (2023-04-23T18:29:50.000Z)
    return excelBaseDate.toISOString();
  }
  const parseFile = async () => {
    try {
      const rawJsonArray = JSON.parse(localStorage.getItem("version3")) || [];
      const currentDate = localStorage.getItem("version3_date") || null;
      const invoiceNumber =
        parseInt(localStorage.getItem("version3_number")) || null;

      const invoiceCode = localStorage.getItem("version3_code") || null;
      const invoiceAlphabets =
        localStorage.getItem("version3_alphabets") || null;
      const invoiceYear = localStorage.getItem("version3_year") || null;
      const className = localStorage.getItem("version3_class") || null;

      if (!rawJsonArray.length || !invoiceNumber || !currentDate) {
        console.warn(
          "Missing required data: JSON array, invoice number, or date."
        );
        return;
      }

      setCustomDate(currentDate);
      setInvoiceNumber(invoiceNumber);
      setInvoiceCode(invoiceCode);
      setInvoiceAlphabets(invoiceAlphabets);
      setSelectedYears(invoiceYear);
      setClassValue(className);

      // Clean and deduplicate JSON array
      const cleanedData = rawJsonArray.map((item) => {
        const result = Object.fromEntries(
          Object.entries(item).map(([key, value]) => {
            const result = [
              key,
              typeof value === "string" ? value.trim() : value,
            ];

            return result;
          })
        );
        return result;
      });

      const uniqueData = cleanedData.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (v) =>
              v["Agency Id"] === item["Agency Id"] &&
              v["Provider Id"] === item["Provider Id"] &&
              v["Practitioner Id"] === item["Practitioner Id"] &&
              v["Appointment Date"] === item["Appointment Date"]
          )
      );

      const providerGroups = uniqueData.reduce((acc, item) => {
        const providerName = item["Practitioner Name"];
        if (!acc[providerName]) {
          acc[providerName] = {
            provider_id: item["Provider Id"],
            practitioner_Id: item["Practitioner Id"],
            provider_name: providerName,
            data: [],
          };
        }
        acc[providerName].data.push(item);
        return acc;
      }, {});

      const finalDump = Object.values(providerGroups);
      const errorSet = new Set();
      const providerMap = new Map();
      let invoiceCounter = parseInt(invoiceNumber, 10) || 0;

      const formattedDate = (date) =>
        new Date(date).toLocaleDateString("en-US");
      const getDueDate = (date, days) => {
        const dueDate = new Date(date);
        dueDate.setDate(dueDate.getDate() + days);
        return formattedDate(dueDate);
      };

      const rateMapping = {
        "half day": "Rate4",
        "full day (8 hours)": "Rate8",
        "full day (10 hours)": "Rate10",
        "full day (15 hours)": "Rate15",
      };

      const mapMasterData = (iterator, masterData) => {
        for (const master of masterData) {
          if (
            String(master.PractionerID)?.trim() ===
              String(iterator?.["Practitioner Id"])?.trim() &&
            String(master.ProviderID)?.trim() ===
              String(iterator["Provider Id"])
          ) {
            return {
              ...master,
              rate: parseFloat(
                master[rateMapping[iterator["Daily Pay Type"].toLowerCase()]] ??
                  master["Rate8"]
              ),
            };
          }
        }
        return null;
      };

      const practitionerLocationTracker = {};

      for (const group of finalDump) {
        const providerData = group.data;

        for (const entry of providerData) {
          const masterMatch = mapMasterData(entry, masterData);
          if (masterMatch) {
            const practitionerId = masterMatch.PractionerID;
            const currentLocation = masterMatch["Location"];

            const trackerId = `${practitionerId}-${currentLocation}`;

            // Initialize tracker for the practitioner if not already present
            if (!practitionerLocationTracker[practitionerId]) {
              practitionerLocationTracker[practitionerId] = {
                previousInvoiceCount: invoiceCounter,
              };
            }

            if (!practitionerLocationTracker[practitionerId][trackerId]) {
              practitionerLocationTracker[practitionerId][trackerId] = {
                locationTrackCount:
                  practitionerLocationTracker[practitionerId]
                    .previousInvoiceCount,
              };

              practitionerLocationTracker[
                practitionerId
              ].previousInvoiceCount += 1;
            }

            // Generate InvoiceNo with suffix if location has changed
            const baseInvoiceNo = `${invoiceCode}${invoiceAlphabets}${
              practitionerLocationTracker[practitionerId][trackerId]
                .locationTrackCount
            }${invoiceYear.toString().slice(-2)}`;
            entry["InvoiceNo"] = `${baseInvoiceNo}`;
            entry["Rate"] = masterMatch.rate;
            entry["Amount"] = masterMatch.rate;
            entry["Location"] = masterMatch["Location"];
            entry["Provider ID"] = masterMatch.ProviderID;
            entry["Practitioner ID"] = masterMatch.PractionerID;
            entry["Customer"] = "OptumServe Health";
            invoiceCounter =
              practitionerLocationTracker[practitionerId].previousInvoiceCount;
          } else {
            errorSet.add(group.provider_name);
            Object.assign(entry, {
              Rate: -1,
              Amount: -1,
              InvoiceNo: "N.A.",
              Location: "-",
              ItemDescription2: "-",
            });
          }

          Object.assign(entry, {
            InvoiceDate: formattedDate(currentDate),
            DueDate: getDueDate(currentDate, 15),
            "Total Hours Worked": entry["Total Hours Worked"],
            ItemQuantity: 1,
            Class: className,
            ServiceDate: excelSerialToDate(entry["Appointment Date"]),
            Terms: "Net 15",
            Taxable: "N",
          });

          // Remove unnecessary fields
          [
            "appointment_duration_in_hrs",
            "availability_type",
            "employer_name",
            "no_of_scheduled_appointments",
            "pay_condition",
            "practitoner_speciality",
            "Daily Pay Type",
            "Appointment Date",
          ].forEach((key) => delete entry[key]);
        }

        providerMap.set(group.provider_name, group);
      }

      setFileName(Object.keys(providerGroups));
      setFileData(providerMap);
      setErrorList(Array.from(errorSet));
    } catch (error) {
      console.error("Error in parseFile:", error);
    }
  };
  const generateId = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    return Array.from({ length }, () =>
      chars.charAt(Math.floor(Math.random() * chars.length))
    ).join("");
  };

  const addNewProvider = async () => {
    setLoader(true);
    // Define a function to add a new provider
    const addProviderToDatabase = async (formData) => {
      const id = generateId(5);
      try {
        await ref.doc(id).set({
          "Provider Name": formData.provider_name,
          Location: "",
          ProviderID: formData.provider_id,
          PractionerID: formData.practitioner_Id,
          Rate4: "",
          Rate8: "",
          Rate10: "",
          Rate12: "",
          Rate15: "",
          XCode: "",
          UID: id,
        });
      } catch (error) {
        console.error("Error adding new provider: ", error);
      }
    };

    // Collect the promises from each provider addition
    const providerPromises = errorList.map((error) => {
      const formData = fileData.get(error);
      return addProviderToDatabase(formData);
    });

    try {
      await Promise.all(providerPromises);
    } catch (error) {
      console.error("Error in adding providers: ", error);
    } finally {
      setLoader(false);
      setOpenModel(false);
      setErrorList([]);
      await parseFile();
    }
  };

  const readExcelToJson = async () => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const binaryStr = event.target.result;

          const workbook = XLSX.read(binaryStr, { type: "binary" });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const jsonHeaders = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });

          const requiredHeaders = [
            "Invoice #",
            "Invoice Date",
            "Agency Id",
            "Agency Name",
            "Provider Id",
            "Practitioner Id",
            "Practitioner Name",
            "Appointment Date",
            "Total Hours Worked",
            "Daily Pay Type",
            "Total Amount Billed",
          ];
          // Validate headers
          const fileHeaders = jsonHeaders[0]; // First row as headers
          const isValidHeaders = requiredHeaders.every((header) => {
            return fileHeaders?.includes(header);
          });

          if (!isValidHeaders) {
            setUploaded(false);
            setUploadedFileData(null);
            closeModal();
            alert(
              "Invalid headers. Please upload a file with the correct format."
            );
            return;
          }

          // Convert each sheet to JSON
          let jsonData = [];
          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            jsonData = [...jsonData, ...XLSX.utils.sheet_to_json(sheet)];
          });
          if (jsonData?.length) {
            localStorage.setItem("version3", JSON.stringify(jsonData));
          }

          parseFile();
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(uploadedFileData);
    });
  };

  const changeInvoice = (invoice) => {
    if (invoice.length <= 5) {
      setInvoiceNumber(invoice);
    }
  };

  const resetUpload = () => {
    localStorage.removeItem("version3");
    localStorage.removeItem("version3_date");
    localStorage.removeItem("version3_number");
    localStorage.removeItem("version3_alphabets");
    localStorage.removeItem("version3_class");
    localStorage.removeItem("version3_code");
    localStorage.removeItem("version3_year");
    localStorage.removeItem("version3_class");

    navigate("/version3/upload");
  };

  const handleAddNewItem = () => {
    const newItem = prompt("Enter new item:");
    if (newItem) {
      const newOption = { value: newItem, label: newItem };
      const updatedOptions = [...options, newOption];

      // Save updated options to localStorage and state
      localStorage.setItem("dropdownOptions", JSON.stringify(updatedOptions));
      setOptions(updatedOptions);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    if (value === "add_new") {
      handleAddNewItem();
    } else {
      setInvoiceCode(value);
    }
  };

  useEffect(() => {
    setProgress("20%");
    setProgressState("File Uploading");
    setUploaded(false);
  }, [location.pathname]);

  useEffect(() => {
    if (errorList.length && localStorage.getItem("role") === "admin") {
      setOpenModel(true);
    }
  }, [errorList.length]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 2001;
    const yearArray = [];

    for (let year = currentYear; year >= startYear; year--) {
      yearArray.push(year);
    }

    setYears(yearArray);

    const savedOptions = JSON.parse(
      localStorage.getItem("dropdownOptions")
    ) || [{ value: "OPD", label: "OPD" }];

    setOptions(savedOptions);
    if (localStorage.getItem("version3") && masterData?.length) {
      setUploaded(true);
      parseFile();
    }
  }, [masterData, localStorage.getItem("version3")]);

  useEffect(() => {
    if (uploaded) {
      // Array to hold the progress steps
      const progressSteps = [
        { state: "Processing the file", progress: "30%" },
        { state: "Detecting duplicates", progress: "40%" },
        { state: "Aligning contents", progress: "55%" },
        { state: "Re-evaluating everything", progress: "70%" },
        { state: "Getting things done", progress: "85%" },
        { state: "File is ready", progress: "100%" },
      ];

      // Array to track timeout IDs for cleanup
      const timers = [];

      const updateProgress = (index) => {
        if (index < progressSteps.length) {
          setProgressState(progressSteps[index].state);
          setProgress(progressSteps[index].progress);
          const timerId = setTimeout(() => updateProgress(index + 1), 1000);
          timers.push(timerId); // Store the timer ID
        } else {
          navigate("/version3/showList"); // Navigate after all steps are completed
        }
      };

      // Start the progress update from the first step
      updateProgress(0);

      // Cleanup function to clear timeouts when the component unmounts
      return () => {
        timers.forEach(clearTimeout); // Clear all timeouts on unmount
      };
    }
  }, [uploaded]); // `navigate` should be included as a dependency

  return (
    <div className="upload">
      {loader && <Loader loading={loader} />}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="master-modal">
          <div
            style={{
              position: "relative",
              tranform: "translate-X(20px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <div className="modal-close-icon" onClick={closeModal}>
              <img src={crossIcon} alt="" style={{ width: "15px" }} />
            </div>{" "}
          </div>
          <div
            className="master-modal-body"
            style={{
              display: "flex",
              flexDirection: "__EMPTY_",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography width="85%" mb="-10px" style={{ color: "white" }}>
              Invoice Number
            </Typography>
            <Stack
              mt={0}
              width="85%"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className="select_group">
                <select
                  style={{ width: "100%", fontWeight: "600" }}
                  onChange={handleChange}
                  value={invoiceCode}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                  <option
                    value="add_new"
                    style={{
                      fontStyle: "italic",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    Add New Item
                  </option>
                </select>
              </div>
              <input
                placeholder="Alphabet"
                style={{ width: "20%", fontWeight: "600" }}
                value={invoiceAlphabets}
                onChange={(e) => {
                  // Convert the input value to uppercase
                  setInvoiceAlphabets(e.target.value?.toUpperCase() || "");
                }}
                onKeyDown={(e) => {
                  // Block number keys (0-9) from being entered
                  if (/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <input
                type="number"
                placeholder="Number"
                style={{ width: "20%", fontWeight: "600" }}
                value={invoiceNumber}
                onChange={(e) => {
                  changeInvoice(e.target.value);
                }}
              />
              <div className="select_group">
                <select
                  style={{ width: "100%", fontWeight: "600" }}
                  value={selectedYears}
                  onChange={(e) => setSelectedYears(e.target.value)}
                >
                  <option value="" disabled selected>
                    Year
                  </option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </Stack>
            <Typography width="85%" mb="-10px" style={{ color: "white" }}>
              Invoice Date
            </Typography>
            <input
              type="date"
              className="calender_input"
              placeholder="Enter Invoice Date"
              style={{ width: "80%", fontWeight: "600" }}
              value={customDate}
              onChange={(e) => {
                setCustomDate(e.target.value);
              }}
            />

            <Typography width="85%" mb="-10px" style={{ color: "white" }}>
              Class
            </Typography>
            <input
              type="text"
              className="calender_input"
              placeholder="Enter Class Name"
              style={{ width: "80%", fontWeight: "600" }}
              value={classValue}
              onChange={(e) => {
                setClassValue(e.target.value);
              }}
            />

            <button
              type="submit"
              // style={{}}
              onClick={() => {
                InvoiceSubmit();
              }}
            >
              <div style={{ fontWeight: "600" }}>SAVE</div>
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={openModel}
        onRequestClose={() => setOpenModel(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="master-modal">
          <div
            style={{
              position: "relative",
              tranform: "translate-X(20px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <div
              className="modal-close-icon"
              onClick={() => setOpenModel(false)}
            >
              <img src={crossIcon} alt="" style={{ width: "15px" }} />
            </div>{" "}
          </div>
          <div
            className="master-modal-body"
            style={{
              display: "flex",
              flexDirection: "__EMPTY_",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography width="85%" mb="-10px" style={{ color: "white" }}>
              Total {errorList.length} new records not present in master list.
              Have to add them?
              {console.log(mapErrorLists(errorList), "ErrorList")}
              {mapErrorLists(errorList).length > 0 ? (
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    marginTop: "10px",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <table style={{ marginTop: "10px" }}>
                    <thead style={{ color: "#0762A3" }}>
                      <tr>
                        <th>Provider Name</th>
                        <th>Practitioner ID</th>
                        <th>Provider ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mapErrorLists(errorList).map((item, index) => (
                        <tr key={index}>
                          <td>{item.provider_name}</td>
                          <td>{item.practitioner_Id}</td>
                          <td>{item.provider_id}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </Typography>
            <Stack direction="row" gap="20px">
              <button
                type="submit"
                onClick={() => {
                  addNewProvider();
                }}
              >
                <div style={{ fontWeight: "600" }}>Yes</div>
              </button>
              <button
                type="submit"
                onClick={() => {
                  setOpenModel(false);
                }}
              >
                <div style={{ fontWeight: "600" }}>No</div>
              </button>
            </Stack>
          </div>
        </div>
      </Modal>

      {location.pathname === "/version3/upload" ? (
        <div className="bg">
          {loading ? (
            <CircularProgress size="30px" sx={{ marginTop: "20px" }} />
          ) : (
            <div className={uploaded ? "uploader active" : "uploader"}>
              <div className="upload-drop">
                <div className="upload-inner">
                  <div className="upload-img">
                    <img src={logo} alt="logo" />
                    <input
                      type="file"
                      hidden=""
                      accept=".xlsx,.xls"
                      id="input1"
                      multiple
                      onChange={handleUpload}
                    />
                  </div>
                </div>
              </div>
              <div className="highlighted-txt">
                {uploaded ? (
                  <p>{progressState}</p>
                ) : (
                  <p>Drag and drop or browse files to upload</p>
                )}
              </div>

              {uploaded ? (
                <div className="upload-loader">
                  <div
                    className="progress-bar"
                    style={{ width: progress }}
                  ></div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : null}

      {location.pathname === "/version3/showList" ? (
        <div className="uploaded">
          <FileShow3
            setData={setData}
            fileName={fileName}
            customDate={customDate}
            fileData={fileData}
            setFileName={setFileName}
            invoiceNumber={invoiceNumber}
            errorList={errorList}
            setNameMasterCode={setNameMasterCode}
            openModal={openModal}
            resetUpload={resetUpload}
          />
        </div>
      ) : null}

      {location.pathname === "/version3/showTable" ? (
        <>
          {data.length === 0 ? (
            <Navigate replace to="/dashboard/upload" />
          ) : null}
          <TablePage3
            data={data?.data}
            customDate={customDate}
            setCustomDate={setCustomDate}
            invoiceNumber={invoiceNumber}
            fileName={fileName}
            nameMasterCode={nameMasterCode}
          />
        </>
      ) : null}
    </div>
  );
}

export default Upload3;
