import React, { useState } from "react";
import Input from "../../Components/Input/Input";
import { db, auth } from "../../firebase/firebase";
import firebase from "firebase";
import visibility from "../../Assets/eye-closed.png";
import google_logo from "../../Assets/Google.png";
import Loader from "../../Components/Loader/Loader";
import "./forget.scss";
import crossIcon from "./../../Assets/cross-icon.svg";

import { Link, useNavigate, Navigate } from "react-router-dom";

function Password__reset() {
  const [loading, setLoading] = useState(false);
  const npg_auth = localStorage.getItem("npg_auth");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const navigate = useNavigate();

  if (msg.length > 0) {
    setTimeout(function () {
      setMsg("");
    }, 7000);
  }
  const Send__link = (event) => {
    event.preventDefault();

    if (email !== "") {
      auth
        .sendPasswordResetEmail(email)
        .then(function () {
          setMsg("Kindly check your mail for a link to reset your password");
          setEmail("");
        })
        .catch((error) => setMsg(error.message));
    } else {
      setMsg("Email field empty");
    }
  };
  return (
    <div className="password_reset">
      <div className="login">
        {npg_auth ? <Navigate replace to="/" /> : null}
        <Loader loading={loading} />
        <div className="modal-close-icon" onClick={() => navigate("/login")}>
          <img src={crossIcon} alt="" style={{ width: "15px" }} />
        </div>{" "}
        <div className="content">
          <h2 style={{ fontWeight: "600" }}>Reset Password</h2>

          <div className="form">
            <div className="input_section">
              <Input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <p
            style={
              msg
                ? {
                    color: "red",
                    marginBottom: "20px",
                    marginTop: "-20px",
                    textAlign: "left",
                    width: "50%",
                  }
                : {}
            }
          >
            {msg}
          </p>

          <button onClick={(e) => Send__link(e)} className="signin">
            Send Reset Link
          </button>
        </div>
      </div>
    </div>
  );
}

export default Password__reset;
