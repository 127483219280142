import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";

import "./Home.scss";
import logoLarge from "./../../Assets/npglogo.png";
import Footer from "../../Components/Footer/Footer";
import Upload3 from "../../Components/Upload/Upload3";

function Home3() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const snapshot = await firebase
        .firestore()
        .collection("Master List2")
        .get();
      const d = snapshot.docs.map((doc) => doc.data());
      setData(d);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setTimeout(() => {
        setLoading(false); // Set loading to false after fetching
      }, 1000);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="home">
      <div className="right">
        <div style={{ maxHeight: "97vh" }}>
          <div className="logoMain">
            <img src={logoLarge} alt="Logo" width={130} />
          </div>
          <div className="switch-btn-holder">
            <button
              className="button-div-add add-provider"
              style={{
                backgroundColor: "#E4E4E4",
                color: "#0762a3",
                fontWeight: "600",
              }}
              onClick={() => navigate("/dashboard/upload")}
            >
              version 1
            </button>
            <button
              className="button-div-add add-provider"
              style={{
                backgroundColor: "#E4E4E4",
                color: "#0762a3",
                fontWeight: "600",
              }}
              onClick={() => navigate("/new/upload")}
            >
              version 2
            </button>
            <button
              className="button-div-add add-provider"
              style={{
                backgroundColor: "#0762a3",
                color: "#E4E4E4",
                fontWeight: "600",
              }}
            >
              version 3
            </button>
          </div>

          <Upload3 masterData={data} loading={loading} />
        </div>

        <div style={{ position: "absolute", bottom: "30px", left: "50%" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home3;
