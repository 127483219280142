import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase/firebase";
import firebase from "firebase";
import "./Register.scss";
import google_logo from "../../Assets/Google.png";
import Input from "../../Components/Input/Input";
import { Link, useNavigate, Navigate } from "react-router-dom";

function Register() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setEmail] = useState("");
  var pattern = new RegExp(/^[0-9\b]+$/);
  const [error_msg, seterror_msg] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [user, setUser] = useState(null);
  const [passwordstatus, setPasswordStatus] = useState("");
  const [passwordColor, setPasswordColor] = useState("");

  function validateEmail() {
    return email.endsWith('@gplusf.com');
  }

  console.log(validateEmail());

  function validatePassword() {
    // Do not show anything when the length of password is zero.
    if (password.length === 0) {
      setPasswordStatus("");
      return;
    }
    // Create an array and push all possible values that you want in password
    var matchedCase = new Array();
    matchedCase.push("[$@$!%*#?&]"); // Special Charector
    matchedCase.push("[A-Z]"); // Uppercase Alpabates
    matchedCase.push("[0-9]"); // Numbers
    matchedCase.push("[a-z]"); // Lowercase Alphabates

    // Check the conditions
    var ctr = 0;
    for (var i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(password)) {
        ctr++;
      }
    }
    // Display it
    var color = "";
    var strength = "";
    switch (ctr) {
      case 0:
      case 1:
      case 2:
        strength = "Very Weak";
        color = "red";
        break;
      case 3:
        strength = "Medium";
        color = "orange";
        break;
      case 4:
        strength = "Strong";
        color = "green";
        break;
    }
    setPasswordStatus(strength);
    setPasswordColor(color);
  }

  useEffect(() => {
    validatePassword();
    console.log(passwordstatus);
  }, [password]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    // animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (error_msg.length > 0) {
    setTimeout(function () {
      seterror_msg("");
    }, 5000);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user]);

  const SignUp = (event) => {
    event.preventDefault();
    if (!validateEmail()) {
      seterror_msg("Only gplusf.com email addresses are allowed");
      setEmail("");
      return;
    } else if (password.length < 6) {
      seterror_msg("Please use a strong password, min. 6 characters");
    } else if (password !== password1) {
      seterror_msg("Passwords do not match");
      //   setPassword("");
      //   setPassword1("");
    } else if (username === "") {
      seterror_msg("Please Enter your First Name");
    } else if (lastname === "") {
      seterror_msg("Please Enter your Last Name");
    } else {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((cred) => {
          navigate("/login");
          alert("User created successfully");
          return db.collection("user").doc(cred.user.uid).set({
            name: username,
            lastname: lastname,
            email: email,
            isAdmin: false,
          });
        })
        .catch((error) => {
          seterror_msg("");
          let errorCode = error.code;
          if (errorCode === "auth/email-already-in-use") {
            seterror_msg("Email Already Exists!");
            setEmail("");
            setPassword("");
            setPassword1("");
          } else if (errorCode === "auth/invalid-email") {
            seterror_msg("Please Enter a Valid mail id");
            setEmail("");
            setPassword("");
            setPassword1("");
          } else if (
            (errorCode === "auth/invalid-password") |
            (errorCode === "auth/weak-password")
          ) {
            seterror_msg("Enter a Strong Password");
            setPassword("");
            setPassword1("");
          }
        });
    }
  };

  const google = (event) => {
    event.preventDefault();
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      hd: 'gplusf.com'
    });

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const email = result.user.email;
        if (!email.endsWith('@gplusf.com')) {
          throw new Error('Only gplusf.com email addresses are allowed');
        }
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        var token = credential.accessToken;
        var user = result.user;
        localStorage.setItem("npg_auth", token);
        navigate("/");
        return db.collection("Hospital").doc(result.user.uid).set({
          Name: user.displayName,
          Email: user.email,
        });
      })
      .catch((error) => {
        seterror_msg(error.message);
      });
  };

  const npg_auth = localStorage.getItem("npg_auth");

  return (
    <div className="register">
      {npg_auth ? <Navigate replace to="/" /> : null}
      <div className="content">
        <h2>Create Account</h2>
        <div className="mt-1 navigate">
          <Link to="/Login">Already have an Account? Sign in</Link>
        </div>

        <div className="form">
          <div className="name_sec">
            <div className="input_section">
              <Input
                type="text"
                placeholder="First Name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="input_section">
              <Input
                type="text"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setlastname(e.target.value)}
              />
            </div>
          </div>

          <div className="input_section">
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input_section">
            <Input
              type="password"
              placeholder="Create Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="input_section">
            <Input
              type="password"
              placeholder="Retype password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
          </div>
        </div>

        <div className="error">
          <p>{error_msg}</p>{" "}
        </div>

        <button onClick={SignUp} className="signin">
          Sign up
        </button>

        <p className="mt-1">or</p>

        <div className="s-google mt-1" onClick={google}>
          <img src={google_logo} alt="google image" />
          <p>Sign up with Google</p>
        </div>
      </div>
    </div>
  );
}

export default Register;
