import React, { useState, useEffect } from "react";
import "./Upload.scss";
import logo from "./../../Assets/uploadLogo.svg";
import FileShow from "../FileShow/FileShow2";
import TablePage from "../../Pages/TablePage/TablePage2";
import * as XLSX from "xlsx/xlsx.mjs";
import { set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import stringSimilarity from "string-similarity";
import Modal from "react-modal";
import crossIcon from "../../Assets/cross-icon.svg";

set_cptable(cptable);
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    minWidth: "550px",
    right: "auto",
    bottom: "auto",
    borderRadius: "24px",
    boxShadow:
      "-16px -16px 40px rgba(253, 255, 255, 0.8), 16px 16px 40px rgba(187, 195, 206, 0.6)",
    padding: "0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

function Upload2(props) {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState([]);
  const [fileData, setFileData] = useState();
  const [errorList, setErrorList] = useState([]);
  const [step, setStep] = useState(1);
  const [uploaded, setUploaded] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState("20%");
  const [progressState, setProgressState] = useState("File Uploaded");
  const navigate = useNavigate();
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [nameMasterCode, setNameMasterCode] = useState();
  const [customDate, setCustomDate] = useState();
  let subtitle;

  const [uploadedFileData, setUploadedFileData] = useState();

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // useEffect
  useEffect(() => {
    setProgress("20%");
    setProgressState("File Uploading");
    setUploaded(false);
  }, [location.pathname]);

  // Invoice Number modal submit triggers this function

  const InvoiceSubmit = () => {
    if (!invoiceNumber) {
      alert("Invoice Number is required");
      return;
    }
    if (!customDate) {
      alert("Date is required");
      return;
    }
    closeModal();
  };

  const cleanExcel = (data) => {
    // console.log(data)

    return new Promise((resolve, reject) => {
      data = data.filter((item) => item !== null);

      var provider = [];


      for (var j in data) {
        Object.keys(data[j]).forEach((key) => {
          if (data[j][key].toString().trim()?.split(":")[0] === "Provider ID") {
            let k = parseInt(j) + 1;
            provider.push(parseInt(j));
          }
        });
      }

      provider.push(data.length);

      // for (var j in data) {
      //   if (data[j].__EMPTY && data[j].__EMPTY_1 && data[j].__EMPTY_14) {
      //     if (data[j].__EMPTY?.split(":")[0] === "Provider ID") {
      //       let k = parseInt(j) + 1;
      //       provider.push(parseInt(j));
      //     }
      //   }
      // }
      console.log("provider", provider);

      var providers = [];



      for (var k = 0; k < provider.length; k++) {

        if (data[provider[k]]) {

          const obj = {
            provider_id: data[provider[k]].__EMPTY?.split(":")[1],
            provider_name: data[provider[k]].__EMPTY_1?.split(":")[1],
            employer_name: data[provider[k]].__EMPTY_2?.split(":")[1],
            practitoner_speciality:
              data[parseInt(provider[parseInt(k)]) + 1]?.__EMPTY?.split(":")[1],
            practitioner_id:
              data[parseInt(provider[parseInt(k)]) + 1]?.__EMPTY_1?.split(
                ":"
              )[1],
            practitioner_name:
              data[parseInt(provider[parseInt(k)]) + 1]?.__EMPTY_2?.split(
                ":"
              )[1],
            data: [],
          };

          for (var i = provider[k] + 2; i < provider[k + 1]; i++) {
            // console.log(provider[k], provider[k+1])

            // if(!data[i]){
            //   continue;
            // }

            if (data[i]?.__EMPTY && data[i]?.__EMPTY_1 && data[i]?.__EMPTY_1) {
              // if (data[i]?.__EMPTY === "Available Date") {
              //   // console.log('no')
              // } else {
              obj.data.push({
                available_date: data[i].__EMPTY,
                pay_type: data[i].__EMPTY_1,
                // availability_type: data[i].__EMPTY_6,
                // pay_condition: data[i].__EMPTY_12,
                // no_of_scheduled_appointments: data[i].__EMPTY_14,
                // appointment_duration_in_hrs: data[i].__EMPTY_17,
                provider_id: obj.provider_id,
                provider_name: obj.provider_name,
                employer_name: obj.employer_name,
                practitoner_speciality: obj.practitoner_speciality,
                practitioner_id: obj.practitioner_id,
                practitioner_name: obj.practitioner_name,
              });
              // }
            }
            
          }

          providers.push(obj);
        }
      }

      const filteredArray = providers.filter((item) => item.data);
      console.log("filteredArray", providers);
      resolve(providers);
    });
  };

  const handleUpload = async (e) => {
    // setFile(e.target.files);
    // setUploadedFileData(e.target.files[0])
    // // await setUploaded(true);
    // console.log('data1')
    // // await progreeHandler();
    // var xl2json = new ExcelToJSON();
    // xl2json.parseExcel(e.target.files[0]);

    setUploadedFileData(e.target.files[0]);
    await setUploaded(true);
    // await progreeHandler();
    // var xl2json = new ExcelToJSON();
    // await xl2json.parseExcel(e.target.files[0]);
    // progreeHandler();
    openModal();

    // console.log(datum)
    // openModal()
  };

  const checkInvoiceAndDate = async () => {
    var xl2json = new ExcelToJSON();
    await xl2json.parseExcel(uploadedFileData);
    progreeHandler();
  };

  useEffect(() => {
    if (invoiceNumber && customDate) {
      checkInvoiceAndDate();
    }
  }, [invoiceNumber, customDate]);

  const progreeHandler = () => {
    setTimeout(() => {
      setProgressState("Processing the file");
      setProgress("30%");
    }, 0);
    setTimeout(() => {
      setProgressState("Detecting duplicates");
      setProgress("40%");
    }, 1000);
    setTimeout(() => {
      setProgressState("Aligning contents");
      setProgress("55%");
    }, 2000);
    setTimeout(() => {
      setProgressState("Re-evaluating everything");
      setProgress("70%");
    }, 3000);
    setTimeout(() => {
      setProgressState("Getting things done");
      setProgress("85%");
    }, 4000);
    setTimeout(() => {
      setProgressState("File is ready");
      setProgress("100%");
      // setUploaded(true);
      setStep(2);
      navigate("/new/showList");
      // openModal();
    }, 5000);
  };

  const parseFile = async (json_Array, invoiceNumber, customDate) => {
    for (const obj of json_Array) {
      try {
        const keys = Object.keys(obj);
        for (const key of keys) {
          obj[key] = obj[key].trim();
        }
      } catch (err) { }
    }

    for (const obj of json_Array) {
      json_Array = json_Array.filter(function (e) {
        return obj !== e;
      });
      json_Array.push(obj);
    }
    // JSON.stringify(JSON.parse(json_Array));
    const unique = json_Array.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        json_Array.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    const unique_json_Array = unique;

    let providerNames = new Set(
      unique_json_Array.map((item) => item["provider_name"])
    );

    let providerId = new Set(
      unique_json_Array.map((item) => item["provider_id"])
    );

    const finalDump = [];
    const providerIDS = Array.from(providerId);
    providerNames = Array.from(providerNames);

    for (const name of providerNames) {
      const provData = unique_json_Array.filter(
        (item) => item["provider_name"] === name
      );
      unique_json_Array.forEach((item, index) => {
        if (item["provider_name"] === name) {
          unique_json_Array.splice(index, 1);
        }
      });
      const obj = {};
      obj["data"] = [];

      for (const x of provData) {
        obj["provider_id"] = provData[0].provider_id;
        obj["provider_name"] = provData[0].provider_name;
        for (const y of x["data"]) {
          obj["data"].push(y);
        }
      }
      finalDump.push(obj);
    }
    const providerMap = new Map();
    for (const providerName of providerNames) {
      let providerData = finalDump.filter(function (e) {
        return e["provider_name"] === providerName;
      });
      providerMap.set(providerName, providerData);
    }

    const errorSet = new Set();
    let i = 0;
    for (const providerName of providerNames) {
      const [year, month, day] = customDate.split("-");
      var customdate = `${month}/${day}/${year}`;
      var today = new Date(customdate);
      const today_plus_15 = new Date(customDate);
      today_plus_15.setDate(today_plus_15.getDate() + 16);
      const providerData = providerMap.get(providerName);

      if (providerData && providerData?.[0]?.data) {
        for (const iterator of providerData?.[0]?.data) {

          const invoiceAdder = parseInt(i) + parseInt(invoiceNumber);
          let found = false;
          let dataToMap = {};


          //Mapping of master rate list to input file

          for (const ms of props.masterData) {
            dataToMap = {};
            if (!ms["Provider Name"]?.split(",").length === 1) {
            }


            if (
              String(ms.PractionerID)?.trim() ==
                String(iterator?.practitioner_id)?.trim() &&
              String(ms.ProviderID)?.trim() ==
                String(providerData?.[0]?.provider_id)?.trim()
            ) {

              // if (iterator.practitioner_id == 2085981 && ms.PractionerID == 2085981) {
              //   console.log("iterator found 2", iterator, ms, providerData?.[0]?.provider_id)
              // }

              found = true;
              dataToMap = ms;
              iterator["InvoiceNo"] = dataToMap["XCode"] + "-" + invoiceAdder;
              const location_loc = dataToMap["Location"];
              iterator["Location"] = location_loc;
              iterator["ItemDescription2"] =
                iterator["practitioner_name"] +
                " " +
                iterator["practitoner_speciality"] +
                " " +
                location_loc +
                " " +
                "<Provider Id" +
                iterator["provider_id"] +
                ">";

              iterator["Rate"] =
                iterator["pay_type"].toLowerCase().trim() === "half day"
                  ? dataToMap["Rate4"]
                  : iterator["pay_type"].toLowerCase().trim() ===
                    "full day (8 hours)"
                  ? dataToMap["Rate8"]
                  : iterator["pay_type"].toLowerCase().trim() ===
                    "full day (10 hours)"
                  ? dataToMap["Rate10"]
                  : iterator["pay_type"].toLowerCase().trim() ===
                    "full day (12 hours)"
                  ? dataToMap["Rate12"]
                  : dataToMap["Rate8"];

              iterator["Amount"] =
                iterator["pay_type"].toLowerCase() === "half day"
                  ? dataToMap["Rate4"]
                  : iterator["pay_type"].toLowerCase() === "full day (8 hours)"
                  ? dataToMap["Rate8"]
                  : iterator["pay_type"].toLowerCase() === "full day (10 hours)"
                  ? dataToMap["Rate10"]
                  : iterator["pay_type"].toLowerCase() === "full day (12 hours)"
                  ? dataToMap["Rate12"]
                  : dataToMap["Rate8"];
            }
          }

          if (!found) {
            errorSet.add(providerName);
            iterator["Rate"] = -1;
            iterator["Amount"] = -1;
            iterator["InvoiceNo"] = "N.A.";
            iterator["Location"] = "-";
            iterator["ItemDescription2"] = "-";
          }

          iterator["InvoiceDate"] = today.toLocaleDateString("en-US");
          iterator["DueDate"] = today_plus_15.toLocaleDateString("en-US");
          iterator["ItemDescription1"] =
            iterator["practitioner_name"]?.split(" ")[1];
          iterator["ItemQuantity"] = 1;

          delete iterator["appointment_duration_in_hrs"];
          delete iterator["appointment_duration_in_hrs"];
          delete iterator["availability_type"];
          delete iterator["employer_name"];
          delete iterator["no_of_scheduled_appointments"];
          delete iterator["pay_condition"];
          // delete iterator["pay_type"];
          delete iterator["practitoner_speciality"];
          delete iterator["provider_id"];
          delete iterator["practitioner_id"];

          iterator["Terms"] = "Net 15";
          iterator["Taxable"] = "N";
          iterator["Service Date"] = iterator["available_date"];
          iterator["Customer"] = iterator["provider_name"]
            ?.trim()
            ?.split(" ")[0];
        }
        i++;
      }
    }

    const errorArray = Array.from(errorSet);

    setFileName(providerNames);
    setFileData(providerMap);
    setErrorList(errorArray);
  };

  var ExcelToJSON = function () {
    this.parseExcel = async function (file) {
      // console.log('data')
      var reader = new FileReader();

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file);

      reader.onload = async function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
          cellDates: true,
          dateNF: "mm/dd/yyyy;@",
        });

        try {
          const sheetNames = workbook.SheetNames;

          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetNames[0]]
          );
          var json_object = JSON.stringify(XL_row_object);
          let json_data = JSON.parse(json_object);

          console.log("json_data", json_data);

          let json_Array = await cleanExcel(json_data);

          localStorage.setItem("version2", JSON.stringify(json_Array));
          localStorage.setItem("version2_number", invoiceNumber);
          localStorage.setItem("version2_date", customDate);


          
          parseFile(json_Array, invoiceNumber, customDate);
        } catch (err) {
          console.log(err);
        }
      };
    };
  };

  const download = (jsonArray, name) => {
    const newjsonData = [];

    for (var j of jsonArray) {
      for (var i of j?.data) {
        const obj = { ...i };
        obj.practitioner_id = j.provider_id;
        obj.provider_name = j.provider_name;
        obj.employer_name = j.employer_name;
        obj.practitoner_specialty = j.practitoner_specialty;
        obj.practitioner_id = j.practitioner_id;
        newjsonData.push(obj);
      }
    }
    var worksheet = XLSX.utils.json_to_sheet(newjsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `${name}.xlsx`);
  };

  const changeInvoice = (invoice) => {
    if (invoice.length <= 5) {
      setInvoiceNumber(invoice);
    }
  };

  const resetUpload = () => {
    localStorage.removeItem("version2");
    localStorage.removeItem("version2_date");
    localStorage.removeItem("version2_number");
    navigate("/new/upload");
    window.location.reload();
  };

  const getFile = () => {
    return localStorage.getItem("version2") &&
      localStorage.getItem("version2") != "undefined"
      ? JSON.parse(localStorage.getItem("version2"))
      : false;
  };

  const [localFile, setLocalFile] = useState(getFile());

  useEffect(() => {
    if (localFile && props.masterData) {
      const csDate =
        localStorage.getItem("version2_date") &&
        localStorage.getItem("version2_date") != "undefined"
          ? localStorage.getItem("version2_date")
          : false;
      const invNo =
        localStorage.getItem("version2_number") &&
        localStorage.getItem("version2_number") != "undefined"
          ? localStorage.getItem("version2_number")
          : false;
      setUploaded(true);

      parseFile(getFile(), invNo, csDate);
      progreeHandler();
    }
  }, [localFile, props?.masterData]);

  return (
    <div className="upload">
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="master-modal">
          <div
            style={{
              position: "relative",
              tranform: "translate-X(20px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <div className="modal-close-icon" onClick={closeModal}>
              <img src={crossIcon} alt="" style={{ width: "15px" }} />
            </div>{" "}
          </div>
          <div
            className="master-modal-body"
            style={{
              display: "flex",
              flexDirection: "__EMPTY_",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <input
              type="number"
              placeholder="Enter Invoice Number"
              style={{ width: "80%", fontWeight: "600" }}
              value={invoiceNumber}
              onChange={(e) => {
                changeInvoice(e.target.value);
                // setInvoiceNumber(e.target.value);
              }}
            />
            <input
              type="date"
              className="calender_input"
              placeholder="Enter Invoice Date"
              style={{ width: "80%", fontWeight: "600" }}
              value={customDate}
              onChange={(e) => {
                setCustomDate(e.target.value);
              }}
            />

            <button
              type="submit"
              // style={{}}
              onClick={() => {
                InvoiceSubmit();
              }}
            >
              {/* <img src={save} alt="save" /> */}
              <div style={{ fontWeight: "600" }}>SAVE</div>
            </button>
          </div>
        </div>
      </Modal>

      {location.pathname === "/new/upload" ? (
        <div className="bg">
          <div className={uploaded ? "uploader active" : "uploader"}>
            <div className="upload-drop">
              <div className="upload-inner">
                <div className="upload-img">
                  <img src={logo} alt="logo" />
                  <input
                    type="file"
                    hidden=""
                    accept=".xlsx,.xls"
                    id="input1"
                    multiple
                    onChange={(e) => handleUpload(e)}
                  />
                </div>
              </div>
            </div>
            <div className="highlighted-txt">
              {uploaded ? (
                <p>{progressState}</p>
              ) : (
                <p>Drag and drop or browse files to upload</p>
              )}
            </div>

            {uploaded ? (
              <div className="upload-loader">
                <div className="progress-bar" style={{ width: progress }}></div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {location.pathname === "/new/showList" ? (
        <div className="uploaded">
          <FileShow
            setData={setData}
            setStep={setStep}
            file={file}
            fileName={fileName}
            customDate={customDate}
            fileData={fileData}
            setFileName={setFileName}
            invoiceNumber={invoiceNumber}
            errorList={errorList}
            setNameMasterCode={setNameMasterCode}
            openModal={openModal}
            resetUpload={resetUpload}
          />
        </div>
      ) : null}

      {location.pathname === "/new/showTable" ? (
        <>
          {data.length === 0 ? (
            <Navigate replace to="/dashboard/upload" />
          ) : null}
          <TablePage
            data={data[0]?.data}
            customDate={customDate}
            setCustomDate={setCustomDate}
            setStep={setStep}
            invoiceNumber={invoiceNumber}
            fileName={fileName}
            nameMasterCode={nameMasterCode}
          />
        </>
      ) : null}
    </div>
  );
}

export default Upload2;
