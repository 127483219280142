import React from "react";
import { useEffect, useState } from "react";
import Upload2 from "../../Components/Upload/Upload2";
import firebase from "firebase";
import Loader from "../../Components/Loader/Loader";

import "./Home.scss";
import logoLarge from "./../../Assets/npglogo.png";
import Footer from "../../Components/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";

function Home2() {
  const [loading, setloading] = useState(0);

  const [data, setData] = useState([]);

  const params = useParams();

  const navigate = useNavigate();

  const getData = async () => {
    const snapshot = await firebase
      .firestore()
      .collection("Master List2")
      .get();
    const d = snapshot.docs.map((doc) => doc.data());
    setData(d);
    return d;
  };

  // const syncData = async () => {
  //   setloading(1);
  //   const dbData = await getData();
  // };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="home">
      <div className="right">
        <div style={{ maxHeight: "97vh" }}>
          <div className="logoMain">
            <img src={logoLarge} alt="" width={130} />
          </div>
          <div className="switch-btn-holder">
            <button
              className="button-div-add add-provider"
              style={{
                backgroundColor: "#E4E4E4",
                color: "#0762a3",
                fontWeight: "600",
              }}
              onClick={() => {
                navigate("/dashboard/upload");
              }}
            >
              version 1
            </button>
            <button
              className="button-div-add add-provider"
              style={{
                backgroundColor: "#0762a3",
                color: "#E4E4E4",
                fontWeight: "600",
              }}
            >
              version 2
            </button>{" "}
            <button
              className="button-div-add add-provider"
              style={{
                backgroundColor: "#E4E4E4",
                color: "#0762a3",
                fontWeight: "600",
              }}
              onClick={() => {
                navigate("/version3/upload");
              }}
            >
              version 3
            </button>
          </div>
          <Loader loading={loading} />
          <Upload2 masterData={data} />
        </div>
        <div style={{ position: "absolute", bottom: "30px", left: "50%" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home2;
